<template>
    <div v-if="editor" class="editor">
        <ul class="editor-toolbar">
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleBold().run()"
                    :class="{ 'is_active': editor.isActive('bold') }" title="Bold">
                    <i class="fa fa-bold" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleItalic().run()"
                    :class="{ 'is_active': editor.isActive('italic') }" title="Italic">
                    <i class="fa fa-italic" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleStrike().run()"
                    :class="{ 'is_active': editor.isActive('strike') }" title="Strike">
                    <i class="fa fa-strikethrough" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleUnderline().run()"
                    :class="{ 'is_active': editor.isActive('underline') }" title="Underline">
                    <i class="fa fa-underline" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleBlockquote().run()"
                    :class="{ 'is_active': editor.isActive('blockquote') }" title="Blockquote">
                    <i class="fa fa-quote-left" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().setTextAlign('left').run()"
                    :class="{ 'is_active': editor.isActive({ textAlign: 'left' }) }" title="Align Left">
                    <i class="fa fa-align-left" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().setTextAlign('center').run()"
                    :class="{ 'is_active': editor.isActive({ textAlign: 'center' }) }" title="Align Center">
                    <i class="fa fa-align-center" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is_active': editor.isActive('bulletList') }" title="List Bullet">
                    <i class="fa fa-list-ul" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is_active': editor.isActive('orderedList') }" title="List Number">
                    <i class="fa fa-list-ol" />
                </a>
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" title="Paragraph">
                    <i class="fa fa-paragraph" /> <b class="caret"></b>
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a href="javascript:void(0)" 
                            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                            :class="{ 'is_active': editor.isActive('heading', { level: 1 }) }" 
                            class="dropdown-item"
                            style="font-size: 22px;"
                            title="Heading 1">Heading 1</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                            :class="{ 'is_active': editor.isActive('heading', { level: 2 }) }" 
                            class="dropdown-item"
                            style="font-size: 20px;"
                            title="Heading 3">Heading 2</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                            :class="{ 'is_active': editor.isActive('heading', { level: 3 }) }" 
                            class="dropdown-item"
                            style="font-size: 18px;"
                            title="Heading 3">Heading 3</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                            :class="{ 'is_active': editor.isActive('heading', { level: 4 }) }" 
                            class="dropdown-item"
                            style="font-size: 16px;"
                            title="Heading 4">Heading 4</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                            :class="{ 'is_active': editor.isActive('heading', { level: 5 }) }" 
                            class="dropdown-item"
                            style="font-size: 14px;"
                            title="Heading 5">Heading 5</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="editor.chain().focus().setParagraph().run()"
                            :class="{ 'is_active': editor.isActive('paragraph') }" 
                            class="dropdown-item"
                            style="font-size: 12px;"
                            title="None">None</a>
                    </li>
                </ul>
            </li>
            <li>
                <a href="javascript:void(0)" @click="addEmbed"
                    :class="{ 'is-active': editor.isActive('link') }" title="Insert Embed Link">
                    <i class="fa fa-film" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="setLink"
                    :class="{ 'is-active': editor.isActive('link') }" title="Insert Link">
                    <i class="fa fa-link" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().unsetLink().run()"
                    v-if="editor.isActive('link')"
                    title="Remove Link">
                    <i class="fa fa-unlink" />
                </a>
            </li>
            <li v-if="showEmbedGallery">
                <a href="javascript:void(0)" @click="editor.chain().focus().insertContent('[IMAGE_SLIDE] <br>').run()" title="Embed Gallery">
                    <i class="fa fa-files-o" />
                </a>
            </li>
            <li v-if="showEmbedAds">
                <a href="javascript:void(0)" @click="editor.chain().focus().insertContent('[ADS] <br>').run()" title="Embed Ads">
                    <small>Ads</small>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$refs.imagemanager.show('image')" title="Insert Image">
                    <i class="fa fa-image" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().undo().run()" title="Undo">
                    <i class="fa fa-undo" />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="editor.chain().focus().redo().run()" title="Redo">
                    <i class="fa fa-repeat" />
                </a>
            </li>
        </ul>
        <editor-content :editor="editor" />
        <ImageManager ref="imagemanager" @selectMedia="setImage" />
    </div>
</template>
<script>
	import getYoutubeId from 'get-youtube-id'
    import Iframe from './iframe'
    import Video from './video'
    import {
        Editor,
        EditorContent
    } from '@tiptap/vue-2';
    import StarterKit from '@tiptap/starter-kit'
    import Image from '@tiptap/extension-image';
    import Document from '@tiptap/extension-document';
    import Paragraph from '@tiptap/extension-paragraph';
    import Heading from '@tiptap/extension-heading';
    import Text from '@tiptap/extension-text';
    import TextAlign from '@tiptap/extension-text-align';
    import ImageManager from '@/components/editor/image_manager';
    import Blockquote from '@tiptap/extension-blockquote';
    import Link from '@tiptap/extension-link';
    import Underline from '@tiptap/extension-underline';

    export default {
        components: {
            EditorContent,
            ImageManager,
        },

        props: {
            value: {
                type: String,
                default: '',
            },
            embedAds: {
                type: Boolean
            }
        },

        data() {
            return {
                editor: null
            }
        },

        computed: {
            showEmbedGallery() {
                return (this.$route.fullPath.toLowerCase().includes('/news/')||this.$route.fullPath.toLowerCase().includes('/article/')) && !this.value.includes('[IMAGE_SLIDE]')
            },
            showEmbedAds() {
                return this.$route.fullPath.toLowerCase().includes('/news/') && !this.value.includes('[ADS]') && this.embedAds
            },
        },

        methods: {
            setImage(media) {
                this.editor.chain().focus().setImage({
                    src: media.file
                }).run()
            },
            addEmbed() {
                const value = window.prompt('Please type the url video')
                if (value) {
                    let url = value;
                    
                    if(value.toLowerCase().includes('youtube.com')) {
                        url = 'https://www.youtube.com/embed/' + getYoutubeId(value)
                    }

                    if(url.substring(url.lastIndexOf(".") + 1) === 'mp4') {
                        this.editor.chain().focus().setVideo({ src: url }).run()
                    } else {
                        this.editor.chain().focus().setIframe({ src: url }).run()
                    }
                }
            },
            setLink() {
                const previousUrl = this.editor.getAttributes('link').href
                const url = window.prompt('URL', previousUrl)

                // cancelled
                if (url === null) {
                    return
                }

                // empty
                if (url === '') {
                    this.editor
                        .chain()
                        .focus()
                        .extendMarkRange('link')
                        .unsetLink()
                        .run()
                    return
                }

                // update link
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({
                        href: url
                    })
                    .run()
            },
        },

        watch: {
            value(value) {
                if(this.value.split('[IMAGE_SLIDE]').length - 1 >= 2) {
                    const first = this.value.replace('[IMAGE_SLIDE]', '[IMAGESLIDER]')
                    const secondOrMore = first.replaceAll('[IMAGE_SLIDE]', '')
                    const finalRes = secondOrMore.replace('[IMAGESLIDER]', '[IMAGE_SLIDE]')

                    this.editor.commands.setContent(finalRes, false)
                    return this.$showToast('Failed', `can only add 1 image slider`, 'danger')
                }

                const isSame = this.editor.getHTML() === value
                if (isSame) {
                    return
                }
                this.editor.commands.setContent(value, false)
            },
        },

        mounted() {
            this.editor = new Editor({
                extensions: [
                    StarterKit,
                    Image,
                    Underline,
                    Document,
                    Paragraph,
                    Text,
                    Heading,
                    Blockquote,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    Link.configure({
                        openOnClick: false,
                    }),
                    Iframe,
                    Video
                ],
                content: this.value,
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML())
                },
            })

            // Listen ads value from parent
            this.$watch('embedAds', () => {
                if(!this.embedAds) {
                    const value = this.editor.getHTML().replaceAll('[ADS]', '')
                    this.$emit('input', value)
                    this.editor.commands.setContent(value, false)
                }
            })

            // Send ads state to parent
            this.$watch('showEmbedAds', () => this.$emit('getAdsState', this.showEmbedAds))
        },

        beforeUnmount() {
            this.editor.destroy()
        },
    }
</script>
<style>
    .editor {
        border: 1px solid #e8e8e8;
        border-radius: 13px;
    }

    ul.editor-toolbar {
        list-style: none;
        padding: 12px 15px;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 0;
    }

    ul.editor-toolbar li {
        display: inline;
        position: relative;
    }

    ul.editor-toolbar li a {
        padding: 5px 10px;
        color: #23282c;
        margin: 0 2px;
    }

    ul.editor-toolbar li a.is_active {
        background: #23282c;
        color: #fff;
        border-radius: 5px;
    }

    .ProseMirror {
        padding: 15px 15px;
        min-height:200px;
    }

    .ProseMirror-focused {
        outline: none;
    }

    .ProseMirror blockquote {
        padding-left: 1rem;
        border-left: 2px solid #d8d8d8;
    }
    .ProseMirror a {
        text-decoration: underline;
    }
    .ProseMirror img {
        max-width: 50%;
        height: auto;
    }
    .ProseMirror img.ProseMirror-selectednode {
        outline: 3px solid #68CEF8;
    }

    /* toolbar dropdonw */
    ul.editor-toolbar li ul {
        visibility: hidden;
        opacity: 0;
        display: none;
        background: #e8e8e8;
    }
    ul.editor-toolbar li:hover > ul,
    ul.editor-toolbar li ul:hover {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    ul.editor-toolbar li ul li {
        display: block;
    }
    ul.editor-toolbar li ul li a {
        padding: 10px;
        margin: 0;
        border-radius: 0!important;
    }
</style>