<template>
    <b-modal id="ImageManager" size="xl" hide-footer hide-header>
        <template v-if="vars.page === 'list'">
            <b-card class="position-relative">
                <i class="fa fa-close btn-close-modal" @click="hide"></i>
                <h5 class="text-center">
                    Choose Media
                </h5>
                <p class="text-muted size12 text-center mb-4">
                    You can choose image that you have upload before
                </p>
                <div id="media_list" class="srollable-list">
                    <b-row>
                        <template v-if="config.total_data">
                            <b-col v-for="(row, key) in config.rows" :key="key" sm="2" class="mb-4">
                                <div class="box-media-clickable" v-bind:class="{ 'active': Number(row.is_active) === 1}" @click="chooseMedia(row)">
                                    <i v-if="Number(row.is_active) === 1" class="fa fa-check media-selected"></i>
                                    <div class="media-image" :style="'background-image:url(' + row.file + ')'" />
                                </div>
                            </b-col>
                        </template>
                        <b-col sm="12" v-else>
                            <b-alert show variant="light" class="text-center">
                                No media availabe
                            </b-alert>
                        </b-col>
                    </b-row>
                </div>

                <template #footer>
                    <b-button type="button" 
                        variant="primary" 
                        class="btn-min-width border-radius-10 float-right" 
                        :disabled="vars.selectedFile ? false : true"
                        @click="sendMedia">
                        Select Media
                    </b-button>
                    <b-button 
                        type="button" 
                        @click="vars.page='upload'" 
                        variant="border-primary" 
                        class="btn-min-width border-radius-10 float-right mr-3">
                        Upload From Computer
                    </b-button>
                </template>
            </b-card>
        </template>
        <template v-else>
            <form class="form-horizontal" @submit.prevent="doUpload">
                <b-card class="position-relative">
                    <i class="fa fa-close btn-close-modal" @click="hide"></i>
                    <h5 class="text-center">
                        Upload Media
                    </h5>
                    <p class="text-muted size12 text-center mb-4">
                        Upload media so that it can be used without having to re-upload
                    </p>
                    <b-row>
                        <ResponseAlert ref="response" />
                        <b-col sm="12" class="mb-4">
                            <div class="box-file" v-bind:class="{ 'is-invalid': errors.has('media')}">
                                <div class="image-input text-center">
                                    <label for="file-input" class="width-auto">
                                        <input id="file-input" type="file" accept="image/png, image/gif, image/jpeg, image/jpg, image/ico"
                                            v-on:change="addMediaToModel" hidden />
                                        <i class="fa fa-upload"></i> Choose File
                                    </label>
                                    <input type="hidden" v-model="models.file" v-validate="'required'" data-vv-name="media"/>
                                </div>
                            </div>
                        </b-col>
                        <template v-if="models.file">
                            <b-col sm="5">
                                <div class="border-image">
                                    <b-img :src="getUrlOfFile(models.file)"/>
                                </div>
                            </b-col>
                            <b-col sm="7">
                                <b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
                                    <b-form-input v-model="models.title" v-validate="'required'" data-vv-name="title">
                                    </b-form-input>
                                    <div v-if="errors.has('title')">
                                        <p class="text-danger">{{ errors.first('title') }}</p>
                                    </div>
                                </b-form-group>
                                <b-form-group label="Description">
                                        <b-form-textarea
                                            v-model="models.description"
                                            rows="3"
                                            max-rows="6">
                                        </b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </template>
                    </b-row>
                    <template #footer>
                        <b-button 
                            type="submit" 
                            variant="primary" 
                            class="btn-min-width float-right  border-radius-10">
                            Save
                        </b-button>
                        <b-button 
                            type="button" 
                            @click="vars.page='list'" 
                            variant="border-primary" 
                            class="btn-min-width border-radius-10 float-right mr-3">
                            Cancel
                        </b-button>
                    </template>
                </b-card>
            </form>
        </template>
    </b-modal>
</template>
<style>
    .card-footer {
        border-top: 0;
    }
</style>
<script>
    import api from '@/utils/api.js';
    import table from '@/utils/table.js';
    import ResponseAlert from '@/components/response_alert';
    import { getUrlOfFile } from '@/utils/helpers.js';
    export default {
        name: 'ImageManager',
        components: {
            ResponseAlert,
        },
        data() {
            return {
                config: {
                    uri: this.$route.meta.permission.link,
                    api: api.media,
                    rows: [],
                    total_data: 0,
                    total_page: 0,
                    per_page: 24,
                    page: 1,
                    pagination_type: 'scroll',
                    orderby: "id",
                    sort: "desc",
                    search: {
                        key: ""
                    },
                },
                page: 1,
                models: {
                    status: "active",
                    title: "",
                    description: "",
                    file: ""
                },
                vars: {
                    page: "list",
                    FormData: new FormData(),
                    target: "",
                    selectedFile: ""
                }
            }
        },
        methods: {
            show(target) {
                const _ = this;
                _.vars.target = target
                _.$bvModal.show('ImageManager');
                _.get();
                setTimeout(() => {
                    _.onScollPage();
                }, 2000);
            },
            hide() {
                this.$bvModal.hide('ImageManager');
            },
            get() {
                const _ = this;
                _.config.rows = [];
                table.get(_.config).then(() => {
                    const imageExtension = ['jpg', 'jpeg', 'png']
                    const fileExtension = ['pdf']
                    const extensionMatch = (extensions, title) => extensions.indexOf(title.substring(title.lastIndexOf(".") + 1)) > -1
                    this.config.rows = this.config.rows.filter(data=> {
                        if((_.vars.target === 'thumbnail' || _.vars.target === 'image')){
                            return extensionMatch(imageExtension, data.title)
                        } else if (_.vars.target === 'pdf') {
                            return extensionMatch(fileExtension, data.title)
                        } else {
                            return data
                        }
                    })
                })
            },
            onScollPage() {
                const _ = this;
                const mediaList = document.querySelector('#media_list');
                mediaList.addEventListener('scroll', () => {
                    if(mediaList.scrollTop + mediaList.clientHeight >= mediaList.scrollHeight) {
                        if(_.config.total_page !== _.config.page){
                            _.page += 1;
                            _.config.per_page = _.config.per_page * _.page;
                            _.get();
                        }
                    }
                });                
            },
            addMediaToModel(event) {
                const _ = this;
                _.models.file = event.target.files[0];
                _.models.title = _.models.file.name;
            },
            unSelectMedia(excludeId){
                const _ = this;
                _.config.rows.map(v => {
                    if(v.id != excludeId){
                        v.is_active = 0;
                    }
                });
            },
            chooseMedia(media){
                const _ = this;
                media.is_active = true;
                _.unSelectMedia(media.id);
                _.vars.selectedFile = media;
            },
            sendMedia(){
                const _ = this;
                this.$emit('selectMedia', _.vars.selectedFile)
                _.hide();
            },
            // method form upload
            getUrlOfFile(file) {
				return getUrlOfFile(file);
			},
            doUpload() {
                const _ = this;
                _.vars.FormData = new FormData();
                for (const i in _.models) {
                    _.vars.FormData.append(i, _.models[i]);
                }
                _.$validator.validate().then(valid => {
                    if (valid) {
                        _.$axios.post(api.media, _.vars.FormData)
                            .then(() => {
                                this.models = {
                                    status: "active",
                                    title: "",
                                    description: "",
                                    file: ""
                                },
                                _.vars.page = 'list';
                                _.get();
                            })
                            .catch(error => _.$refs.response.setResponse(error.response.data.message,
                                'danger'));
                    } else {
                        window.scrollTo(0, 0);
                    }
                });
            },
        },
    }
</script>